export const formConf = {
    index: 0,
    formList: [
        {
            title: '80mm',
            width: 334,
            height: 524,
            padding: 10,
            maxNum: 48,
            pageWidth: 80,
            pageHeight: 100
        },
    ]

}

export const inputComponents = [
    {
        label: '单行文本',
        tag: 'el-input',
        tagIcon: 'input',
        placeholder: '请输入',
        defaultValue: undefined,
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        clearable: true,
        prepend: '',
        append: '',
        'prefix-icon': '',
        'suffix-icon': '',
        maxlength: null,
        'show-word-limit': false,
        readonly: false,
        disabled: false,
        required: true,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/input'
    },
    {
        label: '多行文本',
        tag: 'el-input',
        tagIcon: 'textarea',
        type: 'textarea',
        placeholder: '请输入',
        defaultValue: undefined,
        span: 24,
        labelWidth: null,
        autosize: {
            minRows: 4,
            maxRows: 4
        },
        style: { width: '100%' },
        maxlength: null,
        'show-word-limit': false,
        readonly: false,
        disabled: false,
        required: true,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/input'
    },
    {
        label: '密码',
        tag: 'el-input',
        tagIcon: 'password',
        placeholder: '请输入',
        defaultValue: undefined,
        span: 24,
        'show-password': true,
        labelWidth: null,
        style: { width: '100%' },
        clearable: true,
        prepend: '',
        append: '',
        'prefix-icon': '',
        'suffix-icon': '',
        maxlength: null,
        'show-word-limit': false,
        readonly: false,
        disabled: false,
        required: true,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/input'
    },
    {
        label: '计数器',
        tag: 'el-input-number',
        tagIcon: 'number',
        placeholder: '',
        defaultValue: undefined,
        span: 24,
        labelWidth: null,
        min: undefined,
        max: undefined,
        step: undefined,
        'step-strictly': false,
        precision: undefined,
        'controls-position': '',
        disabled: false,
        required: true,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/input-number'
    }
]

export const selectComponents = [
    {
        label: '下拉选择',
        tag: 'el-select',
        tagIcon: 'select',
        placeholder: '请选择',
        defaultValue: undefined,
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        clearable: true,
        disabled: false,
        required: true,
        filterable: false,
        multiple: false,
        options: [{
            label: '选项一',
            value: 1
        }, {
            label: '选项二',
            value: 2
        }],
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/select'
    },
    {
        label: '级联选择',
        tag: 'el-cascader',
        tagIcon: 'cascader',
        placeholder: '请选择',
        defaultValue: [],
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        props: {
            props: {
                multiple: false
            }
        },
        'show-all-levels': true,
        disabled: false,
        clearable: true,
        filterable: false,
        required: true,
        options: [{
            id: 1,
            value: 1,
            label: '选项1',
            children: [{
                id: 2,
                value: 2,
                label: '选项1-1'
            }]
        }],
        dataType: 'dynamic',
        labelKey: 'label',
        valueKey: 'value',
        childrenKey: 'children',
        separator: '/',
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/cascader'
    },
    {
        label: '单选框组',
        tag: 'el-radio-group',
        tagIcon: 'radio',
        defaultValue: undefined,
        span: 24,
        labelWidth: null,
        style: {},
        optionType: 'default',
        border: false,
        size: 'medium',
        disabled: false,
        required: true,
        options: [{
            label: '选项一',
            value: 1
        }, {
            label: '选项二',
            value: 2
        }],
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/radio'
    },
    {
        label: '多选框组',
        tag: 'el-checkbox-group',
        tagIcon: 'checkbox',
        defaultValue: [],
        span: 24,
        labelWidth: null,
        style: {},
        optionType: 'default',
        border: false,
        size: 'medium',
        disabled: false,
        required: true,
        options: [{
            label: '选项一',
            value: 1
        }, {
            label: '选项二',
            value: 2
        }],
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/checkbox'
    },
    {
        label: '开关',
        tag: 'el-switch',
        tagIcon: 'switch',
        defaultValue: false,
        span: 24,
        labelWidth: null,
        style: {},
        disabled: false,
        required: true,
        'active-text': '',
        'inactive-text': '',
        'active-color': null,
        'inactive-color': null,
        'active-value': true,
        'inactive-value': false,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/switch'
    },
    {
        label: '滑块',
        tag: 'el-slider',
        tagIcon: 'slider',
        defaultValue: null,
        span: 24,
        labelWidth: null,
        disabled: false,
        required: true,
        min: 0,
        max: 100,
        step: 1,
        'show-stops': false,
        range: false,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/slider'
    },
    {
        label: '时间选择',
        tag: 'el-time-picker',
        tagIcon: 'time',
        placeholder: '请选择',
        defaultValue: null,
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        disabled: false,
        clearable: true,
        required: true,
        'picker-options': {
            selectableRange: '00:00:00-23:59:59'
        },
        format: 'HH:mm:ss',
        'value-format': 'HH:mm:ss',
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/time-picker'
    },
    {
        label: '时间范围',
        tag: 'el-time-picker',
        tagIcon: 'time-range',
        defaultValue: null,
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        disabled: false,
        clearable: true,
        required: true,
        'is-range': true,
        'range-separator': '至',
        'start-placeholder': '开始时间',
        'end-placeholder': '结束时间',
        format: 'HH:mm:ss',
        'value-format': 'HH:mm:ss',
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/time-picker'
    },
    {
        label: '日期选择',
        tag: 'el-date-picker',
        tagIcon: 'date',
        placeholder: '请选择',
        defaultValue: null,
        type: 'date',
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        disabled: false,
        clearable: true,
        required: true,
        format: 'yyyy-MM-dd',
        'value-format': 'yyyy-MM-dd',
        readonly: false,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/date-picker'
    },
    {
        label: '日期范围',
        tag: 'el-date-picker',
        tagIcon: 'date-range',
        defaultValue: null,
        span: 24,
        labelWidth: null,
        style: { width: '100%' },
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期',
        disabled: false,
        clearable: true,
        required: true,
        format: 'yyyy-MM-dd',
        'value-format': 'yyyy-MM-dd',
        readonly: false,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/date-picker'
    },
    {
        label: '评分',
        tag: 'el-rate',
        tagIcon: 'rate',
        defaultValue: 0,
        span: 24,
        labelWidth: null,
        style: {},
        max: 5,
        'allow-half': false,
        'show-text': false,
        'show-score': false,
        disabled: false,
        required: true,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/rate'
    },
    {
        label: '颜色选择',
        tag: 'el-color-picker',
        tagIcon: 'color',
        defaultValue: null,
        labelWidth: null,
        'show-alpha': false,
        'color-format': '',
        disabled: false,
        required: true,
        size: 'medium',
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/color-picker'
    },
    {
        label: '上传',
        tag: 'el-upload',
        tagIcon: 'upload',
        action: 'https://jsonplaceholder.typicode.com/posts/',
        defaultValue: null,
        labelWidth: null,
        disabled: false,
        required: true,
        accept: '',
        name: 'file',
        'auto-upload': true,
        showTip: false,
        buttonText: '点击上传',
        fileSize: 2,
        sizeUnit: 'MB',
        'list-type': 'text',
        multiple: false,
        regList: [],
        changeTag: true,
        document: 'https://element.eleme.cn/#/zh-CN/component/upload'
    }
]

export const layoutComponents = [
    {
        layout: 'rowFormItem',
        tagIcon: 'row',
        type: 'default',
        justify: 'start',
        align: 'top',
        label: '行容器',
        layoutTree: true,
        children: [],
        document: 'https://element.eleme.cn/#/zh-CN/component/layout'
    },
    {
        layout: 'colFormItem',
        label: '按钮',
        changeTag: true,
        labelWidth: null,
        tag: 'el-button',
        tagIcon: 'button',
        span: 24,
        default: '主要按钮',
        type: 'primary',
        icon: 'el-icon-search',
        size: 'medium',
        disabled: false,
        document: 'https://element.eleme.cn/#/zh-CN/component/button'
    }
]

// 组件rule的触发方式，无触发方式的组件不生成rule
export const trigger = {
    'el-input': 'blur',
    'el-input-number': 'blur',
    'el-select': 'change',
    'el-radio-group': 'change',
    'el-checkbox-group': 'change',
    'el-cascader': 'change',
    'el-time-picker': 'change',
    'el-date-picker': 'change',
    'el-rate': 'change'
}


//和和项目内容
export const heheComponents = [
    {
        title: '基础组件',
        label: '标签文字：',
        tag: 'div',
        width: 200,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "",
        alignItems: "flex-start",
        default: '绑定值',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '标签文字',
        label: '标签文字',
        tag: 'label',
        width: 200,
        labelWidth: 200,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "",
        alignItems: "flex-start",
        default: '',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '绑定值',
        label: '',
        tag: 'value',
        width: 200,
        labelWidth: 0,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "",
        alignItems: "flex-start",
        default: '绑定值',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    // {
    //     title: '间隔行',
    //     label: '',
    //     tag: 'empty',
    //     width: 48,
    //     maxWidth: 48,
    //     fontSize: 12,
    //     labelWidth: 0,
    //     unit: 6.541,
    //     layout: 'feItem',
    //     labelAlign: "left",
    //     textItems: "left",
    //     bindValue: "",
    //     default: '',
    //     bold: false,
    //     changeTag: true,
    // },
    // {
    //     title: '分割线',
    //     label: '',
    //     tag: 'line',
    //     width: 48,
    //     maxWidth: 48,
    //     fontSize: 12,
    //     labelWidth: 0,
    //     unit: 6.541,
    //     layout: 'feItem',
    //     labelAlign: "left",
    //     textItems: "left",
    //     bindValue: "",
    //     default: '-------------------------------------------------------------------------',
    //     bold: false,
    //     changeTag: true,
    // },
    {
        title: '分割线',
        label: '--------------------------------------------------------------------------------------------------------------------------------------------------',
        tag: 'label',
        width: 200,
        layout: 'heheLine',
        changeTag: true,
        isFloat: false,
        marginTop: 0,
        marginLeft: 0,
        top: 0,
        left: 0,
    },
    {
        title: '条形码',
        tag: 'code',
        // width: 200,
        marginTop: 0,
        marginLeft: 0,
        top: 0,
        left: 0,
        isFloat: false,
        layout: 'heheCode',
        bold: false,
        changeTag: true,
    },
    {
        title: '牙位',
        tag: 'tooth',
        width: 200,
        height: 50,
        fontSize: 12,
        tbMargin: 5,
        lrMargin: 5,
        itemMargin: 2,
        layout: 'tooth',
        bindValue: "tooth",
        default: '5,12,28,21,8',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '牙位2',
        tag: 'tooth',
        width: 200,
        height: 50,
        fontSize: 12,
        tbMargin: 5,
        lrMargin: 5,
        itemMargin: 2,
        layout: 'tooth2',
        bindValue: "tooth",
        default: '5,12,28,21,8',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
]
export const dataCard = [
    {
        label: '订单编号',
        value: 'order_sn'
    },
    {
        label: '接受日期',
        value: 'receive_date'
    },
    {
        label: '机构别名',
        value: 'user_name'
    },
    {
        label: '机构名称',
        value: 'organization'
    },
    {
        label: '患者姓名',
        value: 'name'
    },
    {
        label: '医生姓名',
        value: 'doctor'
    },
    {
        label: '医院名称',
        value: 'hospital'
    },
    {
        label: '产品名称',
        value: 'product'
    },
    {
        label: '数量',
        value: 'num'
    },
    {
        label: '牙位',
        value: 'tooth'
    },
    {
        label: '颜色',
        value: 'color'
    },
    {
        label: '设计要求',
        value: 'design_req'
    },
    {
        label: '代收款',
        value: 'amount'
    },
    {
        label: '锆块码',
        value: 'block_code'
    },
    {
        label: '备注',
        value: 'remark'
    }, {
        label: '地区',
        value: 'area_name'
    },
    {
        label: '结算类型',
        value: 'user_type_name'
    },
    {
        label: '交付时间',
        value: 'newTime'
    },
    {
        label: '固定习惯',
        value: 'habits'
    },
    {
        label: '订单属性',
        value: 'attribute'
    },
]

export const preinstallComponents = [
    {
        title: '订单编号',
        label: '订单编号：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "order_sn",
        default: '2312342006',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '接受日期',
        label: '接受日期：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "receive_date",
        default: '2012-12-12',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '机构名称',
        label: '机构名称：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "organization",
        default: '机构名称',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '机构别名',
        label: '机构别名：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "user_name",
        default: '机构名称',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '患者姓名',
        label: '患者姓名：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "name",
        default: '患者姓名',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '医院名称',
        label: '医院名称：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "hospital",
        default: '医院名称',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '医生姓名',
        label: '医生姓名：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "doctor",
        default: '张医生',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '产品名称',
        label: '产品名称：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "product",
        default: '3M-Lava',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '数量',
        label: '数量：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "num",
        default: '1',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '牙位',
        label: '牙位：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "tooth",
        default: '11,12',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '颜色',
        label: '颜色：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "color",
        default: '白色',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '设计要求',
        label: '设计要求：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "design_req",
        default: '设计要求信息',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '代收款',
        label: '代收款：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "amount",
        default: '12.00',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '锆块码',
        label: '锆块码：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "block_code",
        default: '锆块码信息',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '备注',
        label: '备注：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "remark",
        default: '备注信息',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '地区',
        label: '地区：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "area_name",
        default: '北京',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '结算类型',
        label: '结算类型：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "user_type_name",
        default: '顺丰代付',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '交付时间',
        label: '交付时间：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "newTime",
        default: '2012-12-01',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '固定习惯',
        label: '固定习惯：',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "habits",
        default: '2012-12-01',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
    {
        title: '订单属性',
        label: '订单属性',
        tag: 'div',
        width: 150,
        labelWidth: 76,
        labelAlign: "left",
        textItems: "left",
        fontSize: 12,
        layout: 'heheItem',
        bindValue: "attribute",
        default: '加急',
        changeTag: true,
        isFloat: false,
        top: 0,
        left: 0,
        marginTop: 0,
        marginLeft: 0,
    },
]